import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Theme } from '@mui/material';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.grey[500],
    padding: '10px 12px',
    fontWeight: 500,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '16px',
    fontWeight: 500,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  borderBottom: '1px solid #e0e0e0',
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.common.white, //theme.palette.action.hover,
  },
  // hide last border
  // '&:last-child td, &:last-child th': {
  //   border: 0,
  // },
}));

// eslint-disable-next-line no-unused-vars
const useStyles = (theme: Theme) => ({
  paperContainer: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  tableContainer: {
    maxHeight: '67vh',
    width: '100%',
    minWidth: '700px',
    overflow: 'auto',
    // '::-webkit-scrollbar': {
    //   display: 'none',
    // },
  },
  numericWrapper: {
    display: 'flex',
    gap: '2px',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginY: '12px',
  },
});

export default useStyles;
