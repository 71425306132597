import React from 'react';
import FormControl from '@mui/material/FormControl';
import { RadioSelectOptions } from 'interfaces/SelectOptions.interface.ts';
import { CustomInputLabel } from '@zawarski/palmetto-ui-components';
import MSelect, { SelectProps } from '@mui/material/Select';
import { FieldError } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';

interface SelectMenuProps {
  values: RadioSelectOptions[] | string[];
  withLabel?: boolean;
  label?: string;
  id?: string;
  fieldError?: FieldError | undefined;
  required?: boolean;
  helperText?: string;
}

const SelectMenu: React.FC<SelectMenuProps & SelectProps> = ({ withLabel = false, ...props }) => {
  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 300,
        maxWidth: 400,
        paddingTop: 12,
      },
    },
  };

  return (
    <FormControl style={{ width: '100%', margin: 0 }}>
      {withLabel && (
        <CustomInputLabel
          error={Boolean(props.error || props.fieldError)}
          shrink
          htmlFor={props.id}
          style={{ margin: 0 }}>
          {props.label ?? ''} <span className={props.required ? 'required' : ''}></span>{' '}
        </CustomInputLabel>
      )}
      <MSelect
        labelId='demo-multiple-checkbox-label'
        id='demo-multiple-checkbox'
        disabled={props.disabled}
        error={props.error || Boolean(props.fieldError)}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        MenuProps={menuProps}
        variant='standard'
        sx={{ marginTop: 0 }}>
        {props.values && props.values.length
          ? props.values.map((type, idx) => {
              if (type && typeof type === 'object') {
                return (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                );
              } else {
                return (
                  <MenuItem key={idx} value={type}>
                    {type}
                  </MenuItem>
                );
              }
            })
          : null}
      </MSelect>
      {(Boolean(props.fieldError) || props.error) && (
        <FormHelperText error={Boolean(props.fieldError)} style={{ margin: 0 }}>
          {props.helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectMenu;
