import { QueryState } from '@/common/enum';
import { Nullable } from 'interfaces/Nullable.ts';
import { IAData, IADataSet } from 'interfaces/ia.interface.ts';

export type IAStateTypes = {
  queryState: QueryState;
  error: Nullable<string>;
  iaDataset: Nullable<IADataSet>;
  currentEntry: Nullable<IAData>;
};

export const IAInitialState: IAStateTypes = {
  queryState: QueryState.UNDEFINED,
  error: null,
  iaDataset: null,
  currentEntry: null,
};
