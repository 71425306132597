/* eslint-disable no-unused-vars */
import React from 'react';
import useStyles, { StyledTableCell, StyledTableRow } from './MuiTableGrid.styles.ts';
import { NumericFormat as NumberFormat } from 'react-number-format';
import { Paper, useTheme } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import { TableColumns } from 'interfaces/MuiTableTypes.ts';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MoneyIcon from '@mui/icons-material/AttachMoneyRounded';
import { Typo } from 'components/primitive';

interface MuiTableGridProps<T> {
  data: T[];
  columns: TableColumns[];
  onRowClick?: (row: T) => void;
}

function MuiTableGrid<T>({ columns, data }: MuiTableGridProps<T>) {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Paper sx={classes.paperContainer}>
      <TableContainer sx={classes.tableContainer}>
        <Table sx={{ minWidth: 700, maxWidth: '1440px' }} stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns &&
                columns.length > 0 &&
                columns.map((column) => {
                  if (column.enabled) {
                    return (
                      <StyledTableCell
                        key={column.field}
                        align={column?.align ? column?.align : column.numeric ? 'right' : 'center'}
                        style={{ minWidth: `${column.minWidth} px` }}>
                        {column.headerName}
                      </StyledTableCell>
                    );
                  } else {
                    return null;
                  }
                })}
              <StyledTableCell style={{ width: '20px' }}></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.length
              ? data.map((row: any, index: number) => {
                  return (
                    <StyledTableRow
                      key={index}
                      hover
                      onClick={() => {}}
                      // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      {!!columns &&
                        columns.length > 0 &&
                        columns.map(({ align, enabled, field, numeric, minWidth }) => {
                          if (enabled) {
                            const value = row[field];
                            return (
                              <StyledTableCell
                                component='th'
                                scope='row'
                                key={field}
                                align={numeric ? 'right' : align || 'left'}
                                style={{ minWidth: `${minWidth} px`, flex: 1 }}>
                                {!numeric ? (
                                  value
                                ) : (
                                  <Box sx={classes.numericWrapper}>
                                    <MoneyIcon
                                      sx={{ color: theme.palette.grey[400] }}
                                      fontSize='small'
                                    />
                                    <Typo fontWeight={600} color={theme.palette.common.black}>
                                      <NumberFormat
                                        value={value ? value.toFixed(2) : '0.00'}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                      />
                                    </Typo>
                                  </Box>
                                )}
                              </StyledTableCell>
                            );
                          } else {
                            return null;
                          }
                        })}
                      <StyledTableCell align='right' valign='middle'>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <ChevronRightIcon fontSize={'small'} />
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default MuiTableGrid;
