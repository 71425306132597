import { QueryState } from '@/common/enum';
import { Nullable } from 'interfaces/Nullable.ts';
import { PAData, PADataSet } from 'interfaces/pa.interface.ts';

export type PAStateTypes = {
  queryState: QueryState;
  error: Nullable<string>;
  paDataset: Nullable<PADataSet>;
  currentEntry: Nullable<PAData>;
  paTitle: Nullable<string>;
};

export const PAInitialState: PAStateTypes = {
  queryState: QueryState.UNDEFINED,
  error: null,
  paDataset: null,
  currentEntry: null,
  paTitle: null,
};
