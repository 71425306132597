import React, { useState } from 'react';
import { useTheme } from '@mui/material';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import useStyles from './ScrollTab.styles.ts';
import { IScrollTabOption } from 'interfaces/NavTabsOptionTypes.ts';

type ScrollTabProps = {
  options: IScrollTabOption[];
  selectedIndex: number;
  setIndex: (idx: number) => void;
  // eslint-disable-next-line no-unused-vars
  onChange?: (idx: number) => void;
};

const ScrollTab: React.FC<ScrollTabProps> = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme, tabsClasses);
  const [selectedIndex, setSelectedIndex] = useState(props.selectedIndex);

  const handleChange = (idx: number) => {
    setSelectedIndex(() => idx);
    props.setIndex(idx);
    // props.onChange(props.options[idx].id);
  };

  return (
    <Tabs
      aria-label='visible arrows tabs'
      sx={classes.tabs}
      value={selectedIndex}
      variant='scrollable'
      scrollButtons
      indicatorColor={'secondary'}>
      {props.options && props.options.length
        ? props.options.map((item, idx) => {
            return <Tab key={item.id} label={item.title} onClick={() => handleChange(idx)} />;
          })
        : null}
    </Tabs>
  );
};

export default ScrollTab;
