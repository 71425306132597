export const ProjectColor: { [key: string]: string } = {
  Destroyed: '#CB0133',
  Major: '#003771',
  Minor: '#007499',
  Affected: '#058237',
};

interface ICost {
  [key: string]: string | number;
}

interface ICountiesLat {
  [key: string]: [number, number];
}

export const DefaultEstCostByCategory: ICost[] = [
  {
    category: 'A',
    sum: 0,
  },
  {
    category: 'B',
    sum: 0,
  },
  {
    category: 'C',
    sum: 0,
  },
  {
    category: 'D',
    sum: 0,
  },
  {
    category: 'E',
    sum: 0,
  },
  {
    category: 'F',
    sum: 0,
  },
  {
    category: 'G',
    sum: 0,
  },
];

export const GraphLabels = ['Destroyed', 'Major', 'Minor', 'Affected', 'Inaccessible', 'No Damage'];

export const CategoryLabels = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];

export const CountiesLatLng: ICountiesLat = {
  abbeville: [34.222558, -82.45869028],
  aiken: [33.54428877, -81.63488563],
  allendale: [32.98809752, -81.35818686],
  anderson: [34.51907967, -82.63785003],
  bamberg: [33.21476524, -81.05418099],
  barnwell: [33.26606299, -81.43496618],
  beaufort: [32.38598881, -80.73775138],
  berkeley: [33.19953074, -79.95116557],
  calhoun: [33.6749215, -80.78032952],
  charleston: [32.83179346, -79.96479733],
  cherokee: [35.04818711, -81.62038381],
  chester: [34.6920323, -81.15950872],
  chesterfield: [34.63973818, -80.1586943],
  clarendon: [33.66583509, -80.21638358],
  colleton: [32.86392321, -80.66690528],
  darlington: [34.33232379, -79.95770265],
  dillon: [34.39151494, -79.37893085],
  dorchester: [33.079478, -80.40545871],
  edgefield: [33.77230754, -81.96655907],
  fairfield: [34.39502956, -81.12121674],
  florence: [34.02436799, -79.70278992],
  georgetown: [33.43812646, -79.33527393],
  greenville: [34.89433587, -82.37075865],
  greenwood: [34.15385456, -82.12594279],
  hampton: [32.77628975, -81.14066696],
  horry: [33.92160892, -78.99702957],
  jasper: [32.43791006, -81.03278728],
  kershaw: [34.33870498, -80.59026633],
  lancaster: [34.68662637, -80.70536889],
  laurens: [34.48357283, -82.00590984],
  lee: [34.1632594, -80.25450926],
  lexington: [33.90230383, -81.27223514],
  mccormick: [33.89957597, -82.30988121],
  marion: [34.08003837, -79.36248289],
  marlboro: [34.60197269, -79.67861644],
  newberry: [34.28978674, -81.60008511],
  oconee: [34.75348893, -83.06588453],
  orangeburg: [33.4390023, -80.80024729],
  pickens: [34.88745627, -82.72533547],
  richland: [34.02175901, -80.9030207],
  saluda: [34.00610201, -81.72689703],
  spartanburg: [34.93124924, -81.99070879],
  sumter: [33.91619299, -80.38230801],
  union: [34.68927445, -81.61944696],
  williamsburg: [33.61991716, -79.72771405],
  york: [34.97475045, -81.18440248],
  // scemd: [34.02175901, -80.9030207],
};

export const PACategories: { path: string; category: string; title: string }[] = [
  {
    path: 'a',
    category: 'A',
    title: 'Debris Removal',
  },
  {
    path: 'b',
    category: 'B',
    title: 'Protective Measures',
  },
  {
    path: 'c',
    category: 'C',
    title: 'Roads, Signs, Bridges',
  },
  {
    path: 'd',
    category: 'D',
    title: 'Water Control Facilities',
  },
  {
    path: 'e',
    category: 'E',
    title: 'Buildings & Equipment',
  },
  {
    path: 'f',
    category: 'F',
    title: 'Public Utilities',
  },
  {
    path: 'g',
    category: 'G',
    title: 'Parks, Recreation, & Other',
  },
];

export const Counties = [
  'Aiken',
  'Beaufort',
  'Berkeley',
  'Calhoun',
  'Charleston',
  'Cherokee',
  'Chester',
  'Chesterfield',
  'Clarendon',
  'Colleton',
  'Darlington',
  'Dillon',
  'Dorchester',
  'Edgefield',
  'Fairfield',
  'Greenville',
  'Greenwood',
  'Hampton',
  'Jasper',
  'Kershaw',
  'Laurens',
  'Lee',
  'Lexington',
  'Marion',
  'Marlboro',
  'McCormick',
  'Newberry',
  'Orangeburg',
  'Pickens',
  'Richland',
  'Saluda',
  'Spartanburg',
  'Sumter',
  'Union',
  'York',
];
