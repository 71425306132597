import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PAInitialState, PAStateTypes } from './pa.state.ts';
import { fetchPADataSet } from 'store/feature/pa/pa.thunk.ts';
import { QueryState } from '@/common/enum';
import { PAData } from 'interfaces/pa.interface.ts';

const initialState: PAStateTypes = PAInitialState;

export const paSlice = createSlice({
  name: 'pa',
  initialState,
  reducers: {
    setCurrentPAEntry: (state, action: PayloadAction<PAData | null>) => {
      state.currentEntry = action.payload;
    },
    setCurrentPATitle: (state, action: PayloadAction<string>) => {
      state.paTitle = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPADataSet.pending, (state) => {
        state.queryState = QueryState.AWAIT;
        state.error = null;
      })
      .addCase(fetchPADataSet.fulfilled, (state, action) => {
        state.queryState = QueryState.READY;
        state.paDataset = action.payload ?? null;
      })
      .addCase(fetchPADataSet.rejected, (state, action) => {
        state.queryState = QueryState.FAIL;
        state.error = action.error.message ?? 'Something went wrong!';
      });
  },
});

export const { setCurrentPAEntry, setCurrentPATitle } = paSlice.actions;

export default paSlice.reducer;
