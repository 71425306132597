import React from 'react';
import Box from '@mui/material/Box';
import { HeaderComponent } from '@zawarski/palmetto-ui-components';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, useTheme } from '@mui/material';
import { NavTabs } from 'components/primitive';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { INavTabsOption } from 'interfaces/NavTabsOptionTypes.ts';
import usePageNav, { PageNavItems } from './hooks/usePageNav.ts';
import useStyles from './Dashboard.styles.ts';
import { DashboardProps } from './Dashboard.props.ts';
import IAPage from './IAPage';
import PAPage from './PAPage';

const DashBoardView: React.FC<DashboardProps> = ({ ...props }) => {
  const pageNavBar = usePageNav({ initialItem: PageNavItems.IA });
  const theme = useTheme();
  const classes = useStyles(theme);
  const options: INavTabsOption[] = [
    {
      id: PageNavItems.IA,
      title: 'Individual Assistance',
      mobileTitle: 'Individual',
      icon: <AssignmentIndIcon className={'icon-small'} />,
    },
    {
      id: PageNavItems.PA,
      title: 'Public Assistance',
      mobileTitle: 'Public',
      icon: <AssignmentIcon className={'icon-small'} />,
    },
  ];

  return (
    <Box className='layout vertical full-height'>
      <HeaderComponent
        title='Damage Assessment'
        icon={
          <IconButton onClick={() => props.toggleDrawer()}>
            <MenuIcon className='icon' />
          </IconButton>
        }
      />
      <Box sx={classes.headerNavContainer}>
        <Box sx={classes.headerNavWrapper}>
          <NavTabs
            options={options}
            index={pageNavBar.index}
            setIndex={pageNavBar.setIndex}
            selectedItem={pageNavBar.selectedItem}
            setSelectedItem={pageNavBar.setSelectedItem}
            onChange={pageNavBar.onChange}
            customRootStyle={classes.navTabsRoot}
          />
        </Box>
      </Box>
      {pageNavBar.selectedItem === PageNavItems.IA && <IAPage />}
      {pageNavBar.selectedItem === PageNavItems.PA && <PAPage />}
    </Box>
  );
};

export default DashBoardView;
