const useStyles = () => ({
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '4px',
    // padding: '16px',
    minWidth: '320px',
  },
});

export default useStyles;
