import React from 'react';
import { genericErrorMessage } from '@/services/apiFetch.ts';
import { RootState, store } from '@/store';
import { defer, useLoaderData } from 'react-router-dom';
import { ErrorMessage, Loading } from 'components/primitive';
import { ErrorHandler } from 'components/fragment';
import Box from '@mui/material/Box';
import localforage from 'localforage';

const MapPage = React.lazy(() => import('./MapPageView.tsx'));

// eslint-disable-next-line react-refresh/only-export-components
export const loaderMapPage = async () => {
  try {
    const state = store.getState() as RootState;
    const { userType, selectedGroupName, selectedGroupNameForParcels } = state.accountsInfo;
    if (!userType || !selectedGroupName || !selectedGroupNameForParcels)
      return new Error('User type not found');
    const foundGroup = selectedGroupName ?? selectedGroupNameForParcels;
    // if (userType === UserType.STATE_USER) {
    //   foundGroup = selectedGroupName;
    // } else {
    //   foundGroup = selectedGroupNameForParcels;
    // }
    let localParcelData;
    if (foundGroup) {
      localParcelData = await localforage.getItem(`${foundGroup.toLowerCase()}parcels.mbtiles`);
    }
    return defer({
      groupName: foundGroup,
      localParcelData,
    });
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
};

const MapPageContainer = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data = useLoaderData() as { groupName: string; localParcelData: any };
  if (!data || !data.groupName) {
    return (
      <ErrorHandler headerTitle='Map'>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '500px',
          }}>
          <ErrorMessage
            message={'Please select a group from the sidebar in order to view their parcel data.'}
          />
        </Box>
      </ErrorHandler>
    );
  }
  return (
    <React.Suspense fallback={<Loading />}>
      <MapPage data={data} />;
    </React.Suspense>
  );
};

export default MapPageContainer;
